<template >
    <template v-if="dsPOPurchaseOrder.current">
        <div style="background-color:cornsilk" class="p-2 border rounded">
            <div class="mb-1">
                <div class="row ">
                    <div class="d-flex justify-content-start">
                        <h4 class="mb-0"><a target="_blank" :href="`/nt/purchase-order?ID=${dsPOPurchaseOrder.current.ID}`">PO: {{ dsPOPurchaseOrder.current.ID }}</a></h4>
                        <a  class="btn btn-sm btn-secondary ms-4" :href="`/file/download/reporting/0/arpt_Purchase_PO2/PurchaseOrder_${dsPOPurchaseOrder.current.ID}.pdf?Filter=ID=${dsPOPurchaseOrder.current.ID}`">
                            <i class="bi bi-download"></i> PDF
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4" >
                        <label class="form-label" for="supplierLookup"> {{ $t("Legal Entity") }} </label>
                        <input type="text" class="form-control form-control-sm" v-model="dsPOPurchaseOrder.current.LegalEntity" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4" >
                        <label class="form-label" for="supplierLookup"> {{ $t("Supplier") }} </label>
                        <ODataLookup :data-object="dsPOSuppliers" id="supplierLookup" class="form-select form-select-sm" readonly :disabled="props.readOnly"
                            :bind="sel=>{ dsPOPurchaseOrder.current.Supplier_ID = sel.ID; dsPOPurchaseOrder.current.Supplier = sel.Name;}"
                            :value="dsPOPurchaseOrder.current.Supplier ?? 'No supplier'" >
                            <OColumn field="ID" width="100"></OColumn>
                            <OColumn field="Name" width="300"></OColumn>
                        </ODataLookup>
                    </div>
                    <div class="col-4">
                        <label class="form-label"> {{ $t("Our Reference Person") }} </label>
                        <OPersonsLookup v-model = "dsPOPurchaseOrder.current.OurReferencePerson" class="form-select form-select-sm" readonly :disabled="props.readOnly"
                            :bind="sel=>{console.log(sel); dsPOPurchaseOrder.current.OurReferencePerson_ID=sel.ID; dsPOPurchaseOrder.current.OurReferencePerson = sel.Name}" >
                        </OPersonsLookup>
                    </div>
                    
                    <div class="col-8">
                        <label class="form-label"> {{ $t("Delivery Address") }} </label>
                        <textarea  class="form-control form-control-sm" v-model="dsPOPurchaseOrder.current.DeliveryAddress" rows="3" :disabled="props.readOnly"></textarea>
                    </div>
                    
                    <div class="col-8">
                        <label class="form-label"> {{ $t("Comments") }} </label>
                        <textarea  class="form-control form-control-sm" v-model="dsPOPurchaseOrder.current.Comments" :disabled="props.readOnly"></textarea>
                    </div>

                    <div class="col-8 mt-3">
                        <div class="alert alert-info small p-2 px-3" v-if="dsPOPurchaseOrder.current.Supplier_ID == null">{{ $t("Select supplier before adding items") }}</div>
                    </div>
                </div>
            </div>
            
            <ODataGrid :data-object="dsPOPurchaseOrderItems" hideGridMenu style="min-height:250px">
                <OColumn field="ProductNo" width="150" sortable :editable="!props.readOnly" v-slot:editor="{modelValue: row}">                
                    <ODataLookup 
                        :data-object="dsPOProducts" 
                        :bind="sel=>{row.Product_ID = sel.ID; 
                                    row.Supplier_ID = sel.Supplier_ID;
                                    row.ProductNo = sel.ProductNo; 
                                    row.Product = sel.Name; 
                                    row.Unit_ID = sel.Unit_ID; 
                                    row.Unit = sel.Unit; 
                                    row.ExchangeRate = dsPOExchRates.current.Rate;
                                    row.EstimatedDeliveryDate = dsPOPurchaseOrder.current.DeliveryDate;
                                    row.PricePerUnitWithoutDiscount = sel.Price; 
                                    row.QtyOrdered == null ? row.QtyOrdered = 1 : row.QtyOrdered = row.QtyOrdered; 
                                    console.log(sel.Supplier_ID);}" 
                        v-model="row.ProductNo" readonly>
                            <OColumn field="ProductNo" width="100"></OColumn>
                            <OColumn field="Name" width="350"></OColumn>
                            <OColumn field="Price" width="80"></OColumn>
                    </ODataLookup>
                </OColumn>
                <OColumn field="Product" width="240" :editable="!props.readOnly"> </OColumn>
                <OColumn field="Order" width="120" > </OColumn>
                <OColumn field="OrderItem" width="150" :editable="!props.readOnly" v-slot:editor="{modelValue: row}">
                    <ODataLookup 
                        :data-object="dsOrdersLkp" 
                        :bind="sel=>{row.OrderItem_ID = sel.ID; 
                                    row.OrderItem = sel.Description;}" 
                        v-model="row.OrderItem" readonly>
                            <OColumn field="ID" width="100"></OColumn>
                            <OColumn field="Description" width="350"></OColumn>
                            <OColumn field="Order_ID" width="100"></OColumn>
                            <OColumn field="OrderDescription" width="350"></OColumn>
                    </ODataLookup>
                    </OColumn>
                
                <OColumn field="PricePerUnitWithoutDiscount" title="Price per unit with discount" headerName="Unit Price" class="text-end" width="90" :editable="!props.readOnly" format="1 234.12"></OColumn>
                <OColumn field="QtyOrdered" headerName="Qty" class="text-end" :editable="!props.readOnly" width="80"></OColumn>            
                <OColumn field="Unit" width="60" :editable="!props.readOnly" v-slot:editor="{modelValue: row}">        
                    <ODataLookup 
                        :data-object="dsPOUnits" 
                        :bind="sel=>{row.Unit_ID = sel.ID;  row.Unit = sel.Name; }" 
                        v-model="row.Unit" readonly>
                            <OColumn field="Name" width="80"></OColumn>
                            <OColumn field="description" width="200"></OColumn>
                    </ODataLookup>
                </OColumn>
                <OColumn field="Discount" class="text-end" format="%" width="80" :editable="!props.readOnly"></OColumn>
                <OColumn field="TotalPrice" class="text-end" width="100" format="1 234.12"></OColumn>
                <OColumn field="ExchangeRate" headerName="Exch Rate" class="text-end" width="100"></OColumn>
                <OColumn field="EstimatedDeliveryDate" headerName="Est Del Date" title="Estimated delivery date" width="80" format="Short Date" :editable="!props.readOnly"></OColumn>
                <OColumn field="ActualDeliveryDate" headerName="Act Del Date" title="Actual delivery date"  width="100"></OColumn>
            </ODataGrid>    
        </div>
    </template>
</template>

<script setup>
    import { OPersonsLookup } from 'o365-system-lookups';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { defineProps } from 'vue';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    const procNewPurchaseOrder = getOrCreateProcedure({ id:"procNewPurchaseOrder", procedureName:"astp_Purchase_PurchaseOrderNewFromWorkflow" });

    procNewPurchaseOrder.execute({Workflow_ID: props.itemId }).then((pData)=>{
        dsPOPurchaseOrder.recordSource.whereClause = "ID = " + pData.Table[0].ID;
        dsPOPurchaseOrder.load();
    }).catch((pErr)=>{
        
    });

    const dsPOPurchaseOrder = getOrCreateDataObject(
    {
        "id": "dsPOPurchaseOrder",
        "viewName": "aviw_Purchase_PurchaseOrders",
        "distinctRows": false,
        "uniqueTable": "atbl_Purchase_PurchaseOrders",
        "allowUpdate": props.readOnly?false:true,
        "allowInsert": props.readOnly?false:true,
        "allowDelete": props.readOnly?false:true,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "Supplier"},
            {"name": "OurReferencePerson"},
            {"name": "Currency"},
            {"name": "Supplier_ID"},
            {"name": "OrgUnit_ID"},
            {"name": "OurReferencePerson_ID"},
            {"name": "Workflow_ID"},
            {"name": "LegalEntity"},
            {"name": "LegalEntity_ID"},
            {"name": "DeliveryAddress"},
            {"name": "Comments"},
            
        ],
        "clientSideHandler": false,
        "maxRecords": 10,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const dsPOPurchaseOrderItems = getOrCreateDataObject({
        "id": "dsPOPurchaseOrderItems",
        "viewName": "aviw_Purchase_PurchaseOrdersItems",
        "distinctRows": false,
        "uniqueTable": "atbl_Purchase_PurchaseOrdersItems",
        "allowUpdate":  props.readOnly?false:true,
        "allowInsert":  props.readOnly?false:true,
        "allowDelete":  props.readOnly?false:true,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "PurchaseOrder_ID"},
            {"name": "Product_ID"},
            {"name": "Comments"},
            {"name": "Product"},
            {"name": "QtyOrdered"},
            {"name": "Unit"},
            {"name": "Unit_ID"},
            {"name": "AllowMetaChanges"},
            {"name": "SupplierArticleNo"},
            {"name": "ProductNo"},
            {"name": "PricePerUnitWithoutDiscount"},
            {"name": "TotalPrice"},
            {"name": "Discount"},
            {"name": "PricePerUnit"},
            {"name": "Supplier_ID"},
            {"name": "ExchangeRate"},
            {"name": "ActualDeliveryDate"},
            {"name": "EstimatedDeliveryDate"},
            {"name": "OrderItem"},
            {"name": "OrderItem_ID"},
            {"name": "Order"},
            
        ],
        "masterDataObject_ID": "dsPOPurchaseOrder",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrder_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": "",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    var vTimeout = null;
    dsPOPurchaseOrder.on("FieldChanged", ()=>{
        if(vTimeout){
            clearTimeout(vTimeout);
        }
        vTimeout = setTimeout(()=>{
            dsPOPurchaseOrder.save();
        },400)

        dsPOPurchaseOrderItems.allowInsert = dsPOPurchaseOrder.current.Supplier_ID != null;
    });

    const dsPOProducts = getOrCreateDataObject(
    {
        "id": "dsPOProducts",
        "viewName": "aviw_Stock_ProductsLookup",
        "distinctRows": true,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "Name"},
            {"name": "Description"},
            {"name": "Price"},
            {"name": "Currency"},
            {"name": "ProductGroup"},
            {"name": "ProductGroupIdPath"},
            {"name": "SummaryImagePrimKey"},
            {"name": "Unit_ID"},
            {"name": "AllowMetaChanges"},
            {"name": "SupplierArticleNo"},
            {"name": "Unit"},
            {"name": "HasSubNodes"},
            {"name": "ProductNo"},
            {"name": "Supplier_ID"},
            
        ],
        "masterDataObject_ID": "dsPOPurchaseOrder",
        "masterDetailDefinition": [
            {
                "detailField": "Supplier_ID",
                "operator": "equals",
                "masterField": "Supplier_ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause":  null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    dsPOProducts.fields.loadAlways = ['Unit_ID', 'Unit'];

    const dsPOCurrencies = getOrCreateDataObject(
    {
        "id": "dsPOCurrencies",
        "viewName": "stbv_System_Currencies",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID", "sortOrder": 1, "sortDirection": "asc"},
            {"name": "Currency"},
            {"name": "SortOrder"},
            {"name": "ExchangeRate"}
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false, 
        "whereClause": null,
        "filterString": null
    });

    const dsPOSuppliers = getOrCreateDataObject(
    {
        "id": "dsPOSuppliers",
        "viewName": "aviw_Accounts_Suppliers",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "Name", "sortOrder": 1, "sortDirection": "asc"}
        ],
        "clientSideHandler": false,
        "maxRecords": 100,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "masterDataObject_ID": "dsPOPurchaseOrder",
        "masterDetailDefinition": [
            {
                "detailField": "LegalEntity_ID",
                "operator": "equals",
                "masterField": "LegalEntity_ID"
            }
        ],
    });

    const dsPOUnits = getOrCreateDataObject(
    {
        "id": "dsPOUnits",
        "viewName": "sviw_System_Units",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Name"},
            {"name": "ID"},
            {"name": "Description"}
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": "Closed IS NULL",
        "filterString": null
    });

    const dsPOExchRates = getOrCreateDataObject(
    {
        "id": "dsPOExchRates",
        "viewName": "aviw_Stock_ExchangeRates",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Rate"},
            {"name": "IdPath", "sortOrder": 1, "sortDirection": "asc"},
            {"name": "Currency"},
            {"name": "OrgUnit_ID"}
        ],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null
    });

    const dsOrdersLkp = getOrCreateDataObject(
    {
        "id": "dsOrdersLkp", 
        "viewName": "aviw_Accounts_OrdersItems",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "PrimKey"},
            {"name": "ID"},
            {"name": "Description", "sortOrder": 1, "sortDirection": "asc" },
            {"name": "Order_ID"},
            {"name": "OrderDescription", "sortOrder": 1, "sortDirection": "asc" },
        ],
        "clientSideHandler": false,
        "maxRecords": 100,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null
    });

    dsPOPurchaseOrder.on("DataLoaded",()=>{
        dsOrdersLkp.recordSource.whereClause = "LegalEntity_ID = " + dsPOPurchaseOrder.current.LegalEntity_ID;
        dsPOPurchaseOrderItems.allowInsert = dsPOPurchaseOrder.current.Supplier_ID != null;
    });
</script>